import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { getToken } from '@/auth/utils'
import axios from 'axios'
import Vue from 'vue'
import router from '../../../router'

export default function useInvoicesList() {
  const toast = useToast()
  const refInvoiceListTable = ref(null)
  const tableColumns = [
    { key: 'No', sortable: true, thStyle: { width: '5%' } },
    {
        label: 'Code', key: 'code', sortable: true, thStyle: { width: '7%' },
    },
    {
        label: 'Nama PT', key: 'NamaPT', sortable: true, thStyle: { width: '5%' },
    },
    {
      label: 'Alamat PT', key: 'AlamatPT', sortable: true, thStyle: { width: '5%' },
    },
    {
      label: 'Account No', key: 'AccountNo', sortable: true, thStyle: { width: '5%' },
    },
    {
      label: 'Issued Date', key: 'issued_date', sortable: true, thStyle: { width: '5%' },
    },
    { key: 'Actions', sortable: true },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const branchFilterAll = ref('')
  const startDateFilter = ref('')
  const endDateFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([branchFilterAll,currentPage, perPage, searchQuery, roleFilter, statusFilter, sortBy, isSortDirDesc], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    const userToken = getToken()
    const userRole = Vue.$cookies.get('UserRole')
    let userKodeUnitKerja = userRole !== 'super-admin' && userRole !== 'admin' && userRole !== 'finance' ? Vue.$cookies.get('KodeUnitKerja') : ''
    if (branchFilterAll.value !== '') {
      userKodeUnitKerja = branchFilterAll.value
    }
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    const to = perPage.value * (currentPage.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_INVOICE}/getReceipts?unit_kerja=${userKodeUnitKerja}&limit=${perPage.value}&offset=${to}&search=${searchQuery.value}&type=all&date_start=${startDateFilter.value}&date_end=${endDateFilter.value}`, { headers })
      .then(response => {
        totalItems.value = response.data.total_count
        callback(response.data.Receipts)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    branchFilterAll,
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    // Extra Filters
    roleFilter,
    statusFilter,
    startDateFilter,
    endDateFilter,
  }
}
